import React from 'react';
import ReactPlayer from 'react-player';

import { FixedAspectRatioBox } from './FixedAspectRatioBox';
import { useBreakpoint } from './useBreakpoint';

export interface VideoProps {
  backgroundColor?: string;
  className?: string;
  options?: Options;
  variants: {
    lg?: VideoVariant;
    md?: VideoVariant;
    sm?: VideoVariant;
    xl?: VideoVariant;
    xs: VideoVariant;
    xxl?: VideoVariant;
  };
}

export interface VideoVariant {
  height: number;
  source: {
    bcom?: {
      file: string;
    };
    external?: {
      file?: string;
    };
    vimeo?: {
      id: string;
      privacyHash?: string;
      title?: string;
    };
    youtube?: {
      id: string;
    };
  };
  thumbnail?: string;
  width: number;
}

interface Options {
  autoplay?: boolean;
  controls?: boolean;
  keyboard?: boolean;
  light?: boolean;
  loop?: boolean;
  muted?: boolean;
  pip?: boolean;
  playsinline?: boolean;
  transcript?: boolean;
}

export const Video = ({ backgroundColor, className, options, variants }: VideoProps) => {
  const breakpoint = useBreakpoint();

  const {
    autoplay = false,
    controls = true,
    keyboard = true,
    light = false,
    loop = false,
    muted = false,
    pip = false,
    playsinline = true,
    transcript = true,
  } = options ?? {};

  const variant = React.useMemo(() => {
    let _variant: VideoVariant = variants.xs;

    if (['sm', 'md', 'lg', 'xl', 'xxl'].includes(breakpoint)) {
      _variant = variants.sm ? variants.sm : _variant;
    }
    if (['md', 'lg', 'xl', 'xxl'].includes(breakpoint)) {
      _variant = variants.md ? variants.md : _variant;
    }
    if (['lg', 'xl', 'xxl'].includes(breakpoint)) {
      _variant = variants.lg ? variants.lg : _variant;
    }
    if (['xl', 'xxl'].includes(breakpoint)) {
      _variant = variants.xl ? variants.xl : _variant;
    }
    if (breakpoint === 'xxl') {
      _variant = variants.xxl ? variants.xxl : _variant;
    }

    return _variant;
  }, [breakpoint, variants.lg, variants.md, variants.sm, variants.xl, variants.xs, variants.xxl]);

  const url = React.useMemo(() => getVideoUrl(variant), [variant]);

  return (
    <>
      {url && (
        <FixedAspectRatioBox aspectRatio={variant.width / variant.height} backgroundColor={backgroundColor}>
          <ReactPlayer
            config={{
              vimeo: variant.source.vimeo
                ? {
                    playerOptions: {
                      keyboard: keyboard,
                      transcript: transcript,
                    },
                  }
                : undefined,
            }}
            controls={controls}
            height="100%"
            light={light}
            loop={loop}
            muted={muted}
            pip={pip}
            playing={autoplay}
            playsinline={playsinline}
            // playIcon={}
            // style={}
            url={url}
            // volume={}
            width="100%"
          />
        </FixedAspectRatioBox>
      )}
    </>
  );
};

export const getVideoUrl = (variant: VideoVariant) => {
  if (variant.source.bcom) {
    return variant.source.bcom.file;
  } else if (variant.source.external) {
    return variant.source.external.file;
  } else if (variant.source.youtube) {
    const _url = new URL('https://www.youtube.com/watch');
    _url.searchParams.set('v', variant.source.youtube.id);
    return _url.toString();
  } else if (variant.source.vimeo) {
    const _url = new URL(`https://player.vimeo.com/video/${variant.source.vimeo.id}`);
    variant.source.vimeo.privacyHash && _url.searchParams.set('h', variant.source.vimeo.privacyHash);
    return _url.toString();
  }
};
