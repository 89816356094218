import { ProductApiDto, SkuApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

export const useGallery = (product: ProductApiDto, sku?: SkuApiDto) => {
  const gallery = React.useMemo(() => {
    const slides: Array<{ src: string; thumbnail: string; type: 'image' | 'video'; uId: string }> = [];

    const videoUrl = product.attributes?.find((attribute) => attribute.typeCode.includes('VIDEO_URL'))?.value;
    const videoThumbnail = product.attributes?.find((attribute) =>
      attribute.typeCode.includes('VIDEO_IMAGE_URL')
    )?.value;

    if (sku?.image?.src) {
      slides.push({
        src: sku.image.src,
        thumbnail: sku.image.src,
        type: 'image',
        uId: sku.image.id,
      });
    } else if (product.image?.src) {
      slides.push({
        src: product.image.src,
        thumbnail: product.image.src,
        type: 'image',
        uId: product.image.id,
      });
    }

    if (sku?.alternativeImages) {
      sku.alternativeImages.forEach(
        (image) => image.src && slides.push({ src: image.src, thumbnail: image.src, type: 'image', uId: image.id })
      );
    } else if (product.alternativeImages) {
      product.alternativeImages.forEach(
        (image) => image.src && slides.push({ src: image.src, thumbnail: image.src, type: 'image', uId: image.id })
      );
    }

    if (videoUrl) {
      slides.push({ src: videoUrl, thumbnail: videoThumbnail ?? '', type: 'video', uId: videoUrl });
    }

    return { slides };
  }, [
    sku?.alternativeImages,
    sku?.image,
    product.alternativeImages,
    product.attributes,
    product.image?.id,
    product.image?.src,
  ]);

  return gallery;
};
